import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectTrainerFirstName } from "src/slices/coachSlice";
import { selectFreeTrialDays } from "src/slices/paymentSlice";
import CTAButton from "../Layout/CTAButton";

interface PlanDisplayProps {
  displayCopy: {
    title: React.ReactNode;
    buttonCopy: string;
  };
  onClickNext: () => void;
}

export default function LocalTrainerPlan({
  displayCopy,
  onClickNext,
}: PlanDisplayProps) {
  const { title, buttonCopy } = displayCopy;

  const freeTrialDays = useAppSelector(selectFreeTrialDays);
  const trainerFirstName = useAppSelector(selectTrainerFirstName);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mb: { xs: 6, sm: 6 },
          textAlign: "center",
        }}
      >
        {title}
      </Typography>

      <Grid
        container
        gridTemplateColumns={"2fr 1fr 1fr"}
        style={{
          width: "100%",
          border: "1px solid #E1E0E3",
          borderRadius: "16px",
          display: "grid",
          rowGap: "8px",
          padding: "8px",
        }}
      >
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "8px",
          }}
        >
          <Typography variant="h4"></Typography>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "8px",
          }}
        >
          <Typography variant="h4">Our Remote Trainers</Typography>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "8px",
          }}
        >
          <Typography variant="h4">In-Person Trainers</Typography>
        </Grid>
        <Grid>
          {" "}
          <Box sx={{ flex: 1, ml: 1 }}>
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "-12px",
              }}
            >
              {freeTrialDays} day free trial
            </h2>
            <p style={{ lineHeight: 1.1, fontSize: "15px" }}>
              Start for free and cancel anytime.
            </p>
          </Box>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon fontSize="large" color="success" />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CancelIcon fontSize="large" color="error" />
        </Grid>

        <Grid>
          {" "}
          <Box sx={{ flex: 1, ml: 1 }}>
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "-12px",
              }}
            >
              Unlimited workouts & calls
            </h2>
            <p style={{ lineHeight: 1.1, fontSize: "15px" }}>
              Connect with {trainerFirstName ?? "your trainer"} anytime.
            </p>
          </Box>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon fontSize="large" color="success" />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CancelIcon fontSize="large" color="error" />
        </Grid>

        <Grid>
          {" "}
          <Box sx={{ flex: 1, ml: 1 }}>
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "-12px",
              }}
            >
              Flexible schedule
            </h2>
            <p style={{ lineHeight: 1.1, fontSize: "15px" }}>
              Workout whenever and wherever.
            </p>
          </Box>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon fontSize="large" color="success" />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CancelIcon fontSize="large" color="error" />
        </Grid>

        <Grid>
          {" "}
          <Box sx={{ flex: 1, ml: 1 }}>
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "-12px",
              }}
            >
              Affordable Price
            </h2>
            <p style={{ lineHeight: 1.1, fontSize: "15px" }}>
              Pay 10x less per month.
            </p>
          </Box>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>$99/mo</Typography>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>$1000+/mo</Typography>
        </Grid>
      </Grid>

      <CTAButton onClick={onClickNext} CTAText={buttonCopy} />
    </Container>
  );
}
